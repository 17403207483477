import React, { Suspense, lazy }  from 'react';
import {Link, Route, Routes, useLocation} from 'react-router-dom';
import NavBar from "./components/NavBar";
import BottomBar from "./components/BottomBar";
import { analytics } from './firebase';
import { logEvent } from 'firebase/analytics';
import { useEffect } from 'react';
import LeadershipCarousel from './ScreenComponents/AboutPage/LeadershipCarousel';
import Spinner from './components/Spinner';

function App() {
    const location = useLocation();

    const HomePage = lazy(() => import('./Screens/HomePage'));
    const AboutUs = lazy(() => import('./Screens/AboutUs'));
    const PropertyPage = lazy(() => import('./Screens/PropertyPage'));
    const ContactUs = lazy(() => import('./Screens/ContactUs'));

    useEffect(() => {
        // Log page view event when route changes
        logEvent(analytics, 'entry', {
            page_path: location.pathname,
        });
    }, [location]);

    const isHomePage = location.pathname === '/';

    return (

        <div className="App">
            <NavBar textColor={isHomePage ? 'text-white' : 'text-white'}
                    bordercColor=
                        {isHomePage ? 'border-white' : 'border-white'}

                    bgColor={isHomePage ? 'bg-white' : 'bg-white'
            } />

            {/* Add a Link to the Leadership Carousel outside of Routes */}
            <div className="text-center">
                <Link to="/aboutus#leadership" className="text-blue-500 hover:underline"/>
            </div>

            <Suspense fallback={<Spinner/>}>
                <Routes>
                    <Route path="/leadership" element={<LeadershipCarousel />}/>
                    <Route path="/" element={<HomePage/>}/>
                    <Route path="/aboutus" element={<AboutUs/>}/>
                    <Route path="/:projectId" element={<PropertyPage/>}/>
                    <Route path="/contactus" element={<ContactUs/>}/>
                </Routes>
            </Suspense>
            <BottomBar/>
        </div>

    );
}

export default App;